import Vue from 'vue'
import App from './App.vue'
import './assets/tailwind.css'
import { Amplify } from 'aws-amplify';
import '@aws-amplify/ui-vue'
import router from './router'
import store from './store'
import directives from './directives';
import moment from 'moment';
import VueToast from 'vue-toast-notification';
import 'vue-toast-notification/dist/theme-sugar.css';
import './styles.css'
import InfiniteLoading from 'vue-infinite-loading';

import Vuelidate from 'vuelidate'
Vue.use(Vuelidate)

Vue.use(moment);
Vue.use(VueToast, {
  // One of the options
  position: 'top'
});
Vue.use(InfiniteLoading, { /* options */ });

// Log environment variables to the console
console.log('COGNITO_USER_POOL_ID:', process.env.VUE_APP_COGNITO_USER_POOL_ID);
console.log('COGNITO_USER_POOL_CLIENT_ID:', process.env.VUE_APP_COGNITO_USER_POOL_CLIENT_ID);
console.log('APPSYNC_URL:', process.env.VUE_APP_APPSYNC_URL);

Amplify.configure({
  Auth: {
    region: 'us-west-2',
    userPoolId: process.env.VUE_APP_COGNITO_USER_POOL_ID,
    userPoolWebClientId: process.env.VUE_APP_COGNITO_USER_POOL_CLIENT_ID,
    mandatorySignIn: true
  }
})

const myAppConfig = {
  'aws_appsync_graphqlEndpoint' : process.env.VUE_APP_APPSYNC_URL,
  'aws_appsync_region' : 'us-west-2',
  'aws_appsync_authenticationType': 'AMAZON_COGNITO_USER_POOLS'
}

Amplify.configure(myAppConfig)

Vue.filter('dateformat', function (value) {
  if (!value) return ''
  value = value.toString()
  return moment(value).format('MM/DD/YYYY hh:mm a')
})

Vue.config.productionTip = false

Vue.use(directives);

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')