<template>
  <div class="flex flex-col min-h-screen relative app-wrapper overflow-x-hidden">
    <Header :menuhideshow="(loginstate = true)" />
    <div class="flex-grow w-full container mx-auto relative">
      <!-- <admin-navbar /> -->
      <div class="">
        <div class="w-full pb-4">
          <div class="shadow">
            <router-view />
          </div>
        </div>
        <!-- <div class="flex w-full lg:w-4/12 pt-24 lg:h-full items-top lg-absolute inset-y-0 right-0">
          <News />
        </div> -->
      </div>
    </div>
    <Footer />
    <!-- <HelpBot /> -->
  </div>
</template>
<script>
// import AdminNavbar from "@/components/Navbars/AdminNavbar.vue";
// import Sidebar from "@/components/Sidebar/Sidebar.vue";
import Header from "@/components/Headers/Header.vue";
// import News from "@/components/News.vue";
// import HelpBot from "@/components/HelpBot.vue";
import Footer from "@/components/Footers/Footer.vue";
export default {
  name: "DefaultLayout",
  components: {
    // AdminNavbar,
    // Sidebar,
    Header,
    // News,
    Footer,
    // HelpBot
  },
};
</script>

<style>
.help-bot {
  position: fixed;
  bottom: 0;
  right: 0;
}

.app-wrapper {
  background-color: #0f0e13;
}

.app-container {
  max-width: 1400px;
  width: 100%;
}
</style>
