<template>
  <div
    v-if="modalOpen"
    id="app"
    class="antialiased min-h-screen pt-8 pb-12 bg-gray-100 main-modal"
  >
    <transition
      enter-active-class="transition duration-300 ease-out transform"
      enter-class="scale-95 opacity-0"
      enter-to-class="scale-100 opacity-100"
      leave-active-class="transition duration-150 ease-in transform"
      leave-class="scale-100 opacity-100"
      leave-to-class="scale-95 opacity-0"
    >
      <!-- Modal container -->
      <div
        class="border-1 border-solid border-gray-700 w-full max-w-448-px mx-auto min-h-388-px rounded-14 bg-black-900 p-60"
      >
        <!-- Card container -->

        <!-- Card heading -->
        <div class="flex justify-center">
          <div class="text-center">
            <div v-if="errors != 'error'" class="mb-35">
              <img src="@/assets/submit-success.png" class="mx-auto" />
            </div>
            <div v-if="errors == 'error'" class="mb-35">
              <img
                src="https://img.icons8.com/external-flatart-icons-outline-flatarticons/64/ffffff/external-close-seo-and-media-flatart-icons-outline-flatarticons.png"
                class="mx-auto"
              />
            </div>
            <p class="text-16 leading-36 text-white mb-40">
              {{ popupModalText }}
            </p>
            <button
              type="button"
              class="btn-purple font-sans w-245-px"
              @click="closeModal"
            >
              Return
            </button>
            <div class="text-center">
              <a
                href="#"
                class="text-14 inline-block leading-36 text-white border-b-1 border-b-solid border-gray-700 mt-15 px-5"
                @click="itemPage"
                >{{ textForPopup }}</a
              >
            </div>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import ReportModal from "@/components/Modal/BugReportModal";
export default {
  props: {
    modalText: String,
    textPopup: String,
    redirect: String,
    error: String,
  },
  data() {
    return {
      modalOpen: true,
      advancedSettings: false,
      popupModalText: this.modalText,
      textForPopup: this.textPopup,
      redirectURL: this.redirect,
      errors: this.error,
      value: "",
      flowType: {
        selected: "",
        options: [
          {
            id: 1,
            name: "Auto",
            advanced: false,
          },
          {
            id: 2,
            name: "URL",
            advanced: false,
          },
          {
            id: 3,
            name: "Manual",
            advanced: true,
          },
        ],
      },
    };
  },
  methods: {
    setValue(value) {
      this.value = value;
    },
    updateOption(name) {
      if (this.flowType.selected !== name) {
        this.flowType.selected = name;
      } else if (this.flowType.selected === name) {
        this.flowType.selected = "";
      }
    },
    openModal() {
      this.modalOpen = true;
    },
    closeModal() {
      this.modalOpen = false;
      if (this.redirectURL === "CreateAuction") {
        window.location.href = "/myaccount/2";
      }
      if (this.redirectURL === "ReportPage") {
        this.$parent.closeModal();
        this.$parent.bugReportPopupClose();
        this.$parent.closeFormModal();
      }
    },
    itemPage() {
      if (this.textForPopup === "View item") {
        window.location.href = "/browseauctions";
      }
      if (this.textForPopup === "Report another bug") {
        this.$parent.bugReportPopupClose();
        this.$parent.openModal();
      }
    },
  },
};
</script>

<style>
html {
  font-family: "Inter", sans-serif;
}
.justify-center {
  justify-content: center;
}
</style>
