<template>
  <div class="flex flex-col min-h-screen">
    <footer class="relative border-t-1 border-darkgray border-solid mt-auto">
    <div class="container pt-70 pb-70 lg:pt-135 lg:pb-125 mx-auto px-4">
      <div class="flex flex-wrap justify-between text-center lg:text-left">
        <div class="w-full lg:w-4/10 px-2 logo-beta">
          <img
            src="@/assets/Group 90.png"
            class="m-auto mb-24 lg:m-0 lg:mb-45"
          />
          <h5
            class="text-19 leading-32 lg:text-30 lg:leading-60 mt-0 text-white"
          >
            The only way to trade digital items.
          </h5>
        </div>
        <div class="w-full lg:w-6/10 px-2">
          <div class="flex flex-wrap items-top mb-6">
            <div class="w-full lg:w-4/12 px-2 mt-45 lg:mt-0">
              <!-- <span
                class="block text-white text-16 leading-32 font-medium mb-20 lg:mb-50"
                >Discover</span
              > -->
              <ul class="list-unstyled">
                <li class="">
                  <a
                    class="text-bluesgray hover:text-green font-medium inline-block text-14 leading-32"
                    href="/whitepaper"
                  >
                    White Paper
                  </a>
                </li>
                <li class="mt-20">
                  <a
                    class="text-bluesgray hover:text-green font-medium inline-block text-14 leading-32"
                    href="/Advertise"
                  >
                    Advertise with Bitszer
                  </a>
                </li>
                <!-- <li class="mt-20">
                  <a
                    class="text-bluesgray hover:text-green font-medium inline-block text-14 leading-32"
                    href="/myaccount/3"
                  >
                    Advertise with Bitszer
                  </a>
                </li>
                <li class="mt-20">
                  <a
                    class="text-bluesgray hover:text-green font-medium inline-block text-14 leading-32"
                    href="/browseauctions"
                  >
                    Discover
                  </a>
                </li> -->
                <li class="mt-20">
                  <a
                    class="text-bluesgray hover:text-green font-medium inline-block text-14 leading-32"
                    href="/wallets"
                  >
                    Connected wallets
                  </a>
                </li>
              </ul>
            </div>
            <div class="w-full lg:w-4/12 px-2 mt-45 lg:mt-0">
              <!-- <span class="block text-white text-16 leading-32 font-medium lg:mb-90">I</span> -->
              <ul class="list-unstyled">
                <!-- <li class="">
                  <a
                    class="text-bluesgray hover:text-green font-medium inline-block text-14 leading-32"
                    href="/team"
                  >
                    Bitszer Team
                  </a>
                </li> -->
                <li class="mt-20">
                  <a
                    class="text-bluesgray hover:text-green font-medium inline-block text-14 leading-32"
                    href="/news"
                  >
                    Token News
                  </a>
                </li>
                <!-- <li class="mt-20">
                  <a
                    class="text-bluesgray hover:text-green font-medium inline-block text-14 leading-32"
                    href=""
                  >
                    Support
                  </a>
                </li> -->
                <!-- <li class="mt-20">
                  <a
                    class="text-bluesgray hover:text-green font-medium inline-block text-14 leading-32"
                    href=""
                  >
                    Jobs
                  </a>
                </li> -->
              </ul>
            </div>
            <!-- <div class="w-full lg:w-4/12 px-2 mt-45 lg:mt-0">
              <span
                class="block text-white text-16 leading-32 font-medium mb-20 lg:mb-50"
                >Stay in the Loop</span
              >
              <p class="text-white-100 font-normal text-14 leading-32">
                Subscribe and get the latest updates about future work from
                BITSZER.
              </p>
              <form class="pt-28">
                <div
                  class="border-1 border-gray-100 border-solid rounded-full p-6 flex items-center"
                >
                  <input
                    type="email"
                    class="pl-15 border-0 w-full bg-transparent"
                    placeholder="Enter your email "
                  />
                  <button
                    type="button"
                    class="w-35-px h-35-px rounded-full bg-gray-800 flex flex-35 items-center justify-center"
                  >
                    <img
                      src="@/assets/send.png"
                      class="img-responsive w-22-px"
                    />
                  </button>
                </div>
              </form>
            </div> -->
          </div>
        </div>
      </div>
      <div class="rounded-social-buttons">
        <a
          class="social-button facebook"
          href="https://www.facebook.com/bitszer"
          target="_blank"
          ><i class="fab fa-facebook-f"></i
        ></a>
        <a
          class="social-button twitter"
          href="https://twitter.com/_bitszer"
          target="_blank"
          ><i class="fab fa-twitter"></i
        ></a>
        <a
          class="social-button linkedin"
          href="https://www.linkedin.com/company/81583872"
          target="_blank"
          ><i class="fab fa-linkedin"></i
        ></a>
        <a
          class="social-button youtube"
          href="https://www.youtube.com/channel/UCZlmsocs5JOBOQ9-xucU5Q"
          target="_blank"
          ><i class="fab fa-youtube"></i
        ></a>
        <a
          class="social-button instagram"
          href="https://www.instagram.com/_bitszer"
          target="_blank"
        >
          <i class="fab fa-instagram"></i
        ></a>
        <a
          class="social-button discord"
          href="https://discord.gg/GQhpwnRg"
          target="_blank"
        >
          <i class="fab fa-discord"></i
        ></a>
        <a
          class="social-button telegram"
          href="https://t.me/+sRqqX36CKjMwNThh"
          target="_blank"
        >
          <i class="fab fa-telegram"></i
        ></a>
      </div>
    </div>
    <div class="footer-border h-10-px w-full"></div>
    <div
      class="cursor-pointer bugs-btn fixed bottom-10 right-10 w-63-px h-63-px rounded-full flex items-center justify-center overflow-hidden bg-white"
      v-if="this.$store.state.authentication.loggedIn"
    >
      <img
        src="@/assets/bugs.png"
        class="m-auto h-35-px"
        @click="reportBugPopup"
      />
    </div>
    <ReportModal v-if="openReportBugModel" ref="modalRef"></ReportModal>
  </footer>
</div>
</template>

<script>
import ReportModal from "@/components/Modal/BugReportModal";
export default {
  components: { ReportModal },
  data() {
    return {
      openReportBugModel: false,
    };
  },
  methods: {
    reportBugPopup() {
      this.openReportBugModel = true;
      this.$nextTick(() => {
        this.$refs.modalRef.modalOpen = true;
      });
    },
  },
};
</script>
<style scoped>
.footer-border {
  background-image: linear-gradient(
    91deg,
    #c46bff 0%,
    #810bf3 13%,
    #ffd4f3 100%
  );
}
footer input {
  color: #fff;
}
footer input:focus {
  border: none;
  outline: none;
}
.rounded-social-buttons {
  text-align: center;
  margin-top: 15px;
}

.rounded-social-buttons .social-button {
  display: inline-block;
  position: relative;
  cursor: pointer;
  width: 3.125rem;
  height: 3.125rem;
  border: 0.125rem solid transparent;
  padding: 0;
  text-decoration: none;
  text-align: center;
  color: #fefefe;
  font-size: 1.5625rem;
  font-weight: normal;
  line-height: 2em;
  border-radius: 1.6875rem;
  transition: all 0.5s ease;
  margin-right: 0.25rem;
  margin-bottom: 0.25rem;
}

.rounded-social-buttons .social-button:hover,
.rounded-social-buttons .social-button:focus {
  -webkit-transform: rotate(360deg);
  -ms-transform: rotate(360deg);
  transform: rotate(360deg);
}

.rounded-social-buttons .fa-twitter,
.fa-facebook-f,
.fa-linkedin,
.fa-youtube,
.fa-instagram {
  font-size: 25px;
}

.rounded-social-buttons .social-button.facebook {
  background: #3b5998;
}

.rounded-social-buttons .social-button.facebook:hover,
.rounded-social-buttons .social-button.facebook:focus {
  color: #3b5998;
  background: #fefefe;
  border-color: #3b5998;
}

.rounded-social-buttons .social-button.twitter {
  background: #55acee;
}

.rounded-social-buttons .social-button.twitter:hover,
.rounded-social-buttons .social-button.twitter:focus {
  color: #55acee;
  background: #fefefe;
  border-color: #55acee;
}

.rounded-social-buttons .social-button.linkedin {
  background: #007bb5;
}

.rounded-social-buttons .social-button.linkedin:hover,
.rounded-social-buttons .social-button.linkedin:focus {
  color: #007bb5;
  background: #fefefe;
  border-color: #007bb5;
}

.rounded-social-buttons .social-button.youtube {
  background: #bb0000;
}

.rounded-social-buttons .social-button.youtube:hover,
.rounded-social-buttons .social-button.youtube:focus {
  color: #bb0000;
  background: #fefefe;
  border-color: #bb0000;
}

.rounded-social-buttons .social-button.instagram {
  background: #125688;
}

.rounded-social-buttons .social-button.instagram:hover,
.rounded-social-buttons .social-button.instagram:focus {
  color: #125688;
  background: #fefefe;
  border-color: #125688;
}

.rounded-social-buttons .social-button.discord {
  background: #5865f2;
}

.rounded-social-buttons .social-button.discord:hover,
.rounded-social-buttons .social-button.discord:focus {
  color: #5865f2;
  background: #fefefe;
  border-color: #5865f2;
}

.rounded-social-buttons .social-button.telegram {
  background: #0088cc;
}
.rounded-social-buttons .social-button.telegram:hover,
.rounded-social-buttons .social-button.telegram:focus {
  color: #0088cc;
  background: #fefefe;
  border-color: #0088cc;
}
</style>
