import { getMyProfile } from '@/lib/backend'
import { Auth } from 'aws-amplify';

const state = {
    profile: null
};

const getters = {
    profile: state => state.profile
};

const mutations = {
    SET_PROFILE: (state, payload) => {
        state.profile = Object.assign({}, payload);
    },
};

const actions = {
    async getProfile ({ commit }) {
        try {
            const user = await Auth.currentAuthenticatedUser();
            console.log("user from getProfile", user)
            if (!user) {
                return;
            }
            const result = await getMyProfile();
            commit("SET_PROFILE", result);
        } catch (err) {
            console.log(err);
        }
    },
};

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
};
