<template>
  <div
    v-if="modalOpen"
    id="app"
    class="antialiased min-h-screen pt-8 pb-12 px-10 bg-gray-100 main-modal"
  >
    <transition
      enter-active-class="transition duration-300 ease-out transform"
      enter-class="scale-95 opacity-0"
      enter-to-class="scale-100 opacity-100"
      leave-active-class="transition duration-150 ease-in transform"
      leave-class="scale-100 opacity-100"
      leave-to-class="scale-95 opacity-0"
    >
      <!-- Modal container -->
      <div
        class="border-1 border-solid border-gray-700 w-full max-w-722-px mx-auto min-h-388-px rounded-14 bg-black-900 p-24 lg:p-50 relative"
      >
        <!-- Card container -->

        <!-- Card heading -->
        <div class="flex justify-between">
          <div class="w-full">
            <h6
              class="text-31 lg:text-44 leading-41 lg:leading-54 text-white mb-45"
            >
              Report a bug
            </h6>
            <form ref="form" @submit.prevent="">
              <div class="form-group mb-30">
                <label
                  for="bug-title"
                  class="w-full block text-16 lg:text-18 leading-23 mb-15 text-white"
                  >Title</label
                >
                <input
                  type="text"
                  placeholder="Enter name of bug report"
                  class="w-full pb-2 border-solid border-1 border-gray-100 focus:outline-none bg-transparent focus:bg-gray-800 rounded-12 text-15 text-white px-24 py-12"
                  v-model="form.action"
                  :class="{ 'is-invalid': submitted && $v.form.action.$error }"
                />
                <div
                  v-if="submitted && !$v.form.action.required"
                  class="mt-2 text-sm text-red"
                >
                  Title is required
                </div>
              </div>
              <div class="form-group mb-30">
                <label
                  for="bug-description"
                  class="w-full block text-16 lg:text-18 leading-23 mb-15 text-white"
                  >Description</label
                >
                <textarea
                  type="text"
                  placeholder="Describe your bug"
                  rows="7"
                  class="w-full pb-2 border-solid border-1 border-gray-100 focus:outline-none bg-transparent focus:bg-gray-800 rounded-12 text-15 text-white px-24 py-12 resize-none"
                  v-model="form.bug"
                  :class="{ 'is-invalid': submitted && $v.form.bug.$error }"
                ></textarea>
                <div
                  v-if="submitted && !$v.form.bug.required"
                  class="mt-2 text-sm text-red"
                >
                  Description is required
                </div>
              </div>
              <div class="mt-30 lg:mt-30">
                <button class="btn-purple font-sans" @click="handleSubmit">
                  Submit report
                </button>
              </div>
            </form>
            <button
              type="button"
              class="absolute top-23-px right-10 lg:right-10"
              @click="modalOpen = false"
            >
              <img src="@/assets/clear.png" class="img-responsive" />
            </button>
          </div>
        </div>
      </div>
    </transition>

    <Modal
      v-if="bugReportPopup"
      ref="modalRef"
      :modalText="'Thank you for reporting this bug and helping us refine BITSZER.'"
      :textPopup="'Report another bug'"
      :redirect="'ReportPage'"
    ></Modal>
  </div>
</template>

<script>
import { sendBugReport } from "@/lib/backend.js";
import { mapGetters } from "vuex";
import Modal from "@/components/Modal/Modal";
import { required } from "vuelidate/lib/validators";

export default {
  components: { Modal },
  data() {
    return {
      modalOpen: false,
      advancedSettings: false,
      flowType: {
        selected: "",
        options: [
          {
            id: 1,
            name: "Auto",
            advanced: false,
          },
          {
            id: 2,
            name: "URL",
            advanced: false,
          },
          {
            id: 3,
            name: "Manual",
            advanced: true,
          },
        ],
      },
      form: {
        bug: "",
        action: "",
        profileId: null,
      },
      submitted: false,
      bugReportPopup: false,
    };
  },

  validations: {
    form: {
      bug: { required },
      action: { required },
    },
  },

  computed: {
    ...mapGetters("profile", ["profile"]),
  },

  methods: {
    bugReportPopupClose() {
      this.bugReportPopup = false;
    },
    updateOption(name) {
      if (this.flowType.selected !== name) {
        this.flowType.selected = name;
      } else if (this.flowType.selected === name) {
        this.flowType.selected = "";
      }
    },
    openModal() {
      this.modalOpen = true;
    },
    closeModal() {
      this.modalOpen = false;
    },
    closeFormModal() {
      this.form.action = null;
      this.form.bug = null;
      this.$v.$reset();
      this.submitted = false;
    },
    async handleSubmit() {
      this.submitted = true;

      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }

      this.form.profileId = this.profile.id;
      let res = await sendBugReport(
        this.form.action,
        this.form.bug,
        this.form.profileId
      );
      if (res.data.bugEmail) {
        // this.$toast.success("Successfully sent");
        this.closeFormModal();
        this.reportPopup();
      }
    },
    reportPopup() {
      this.bugReportPopup = true;
      this.$nextTick(() => {
        this.$refs.modalRef.modalOpen = true;
      });
    },
  },
};
</script>

<style>
html {
  font-family: "Inter", sans-serif;
}
</style>
