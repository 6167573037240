<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
import { mapActions } from "vuex";
console.log("app");
export default {
  name: "App",
  component: {},
  methods: {
    ...mapActions("profile", ["getProfile"]),
  },
  // async mounted() {
  //   if(this.$store.state.authentication.loggedIn) {
  //     await this.getProfile();
  //   }
  // },
  watch: {
    "$store.state.authentication.loggedIn": {
      handler: async function () {
        await this.getProfile().then(() => {
          console.log("profile fetched");
        })
      },
    },
  },
};
</script>
