<template>

  <div class="relative app-wrapper overflow-x-hidden">
    <Header :menuhideshow="loginstate = false" />
    <div class=" w-full container mx-auto min-h-600-px">
      <!-- <admin-navbar /> -->
      <div class="">
        <div class="w-full p-80">
          <div class="shadow">
            <router-view />
          </div>
        </div>
        <!-- <div class="flex w-full lg:w-4/12 pt-24 lg:h-full items-top lg-absolute inset-y-0 right-0">
          <News />
        </div> -->
      </div>
    </div>
    <Footer />
    <!-- <HelpBot /> -->
  </div>
</template>

<script>
import Header from "@/components/Headers/Header.vue";
import Footer from "@/components/Footers/Footer.vue";
export default {
  name: 'AuthLayout',
  data() {
    return {
    };
  },
  components: {
    Header,
    Footer
  },
};
</script>

<style>
@media (min-width: 1280px) {
  .xl-flex {
    display: flex;
  }
}

@media (max-width: 1280px) {
  .login {
    background: linear-gradient(to bottom, #1c3faa, #2b51b4);
    background-repeat: no-repeat;
    background-attachment: fixed;
  }

  .login:before {
    display: none;
  }

  .login__input {
    min-width: 100%;
  }
}

.login {
  position: relative;
  background-color: white;
  width: 100%;
}

.login__input {
  min-width: 350px;
  box-shadow: 0px 3px 5px #00000007;
}
</style>
