import Vue from 'vue';
import Vuex from  'vuex';
import authentication from './modules/authentication';
import signup from './modules/signup';
import profile from './modules/profile';
import createPersistedState from "vuex-persistedstate";

Vue.use(Vuex);

console.log("profile from store/index.js", profile)

export default new Vuex.Store({
  modules: {
    authentication,
    signup,
    profile
  },
  plugins: [createPersistedState()],
})