<template>
  <div class="relative flex py-8 header-block border-b border-darkgray">
    <div class="w-full">
      <nav
        class="flex flex-wrap items-center justify-between navbar-expand-lg rounded"
      >
        <div
          class="container mx-auto flex items-end justify-between"
        >
          <div
            class="w-full logo-block border-none lg:border-solid border-r-1 border-darkgray relative flex justify-between lg:w-auto px-35 pl-4 lg:static lg:block lg:justify-start"
          >
            <a
              class="text-sm font-bold leading-relaxed inline-block whitespace-no-wrap uppercase text-white w-140-px logo-beta"
              href="/"
            >
              <img src="@/assets/Group 90.png" />
            </a>
          </div>
          <div
            class="bg-darkgray z-99 pb-20 lg:pb-0 absolute inset-x-0 lg:relative block w-full border-b-1 border-darkgray border-solid lg:border-none lg:w-auto lg:flex lg:flex-grow items-start px-6 mt-8 lg:mt-0"
            id="header-nav"
            :class="isActive ? 'hideshowdiv' : ''"
          >
            <ul v-if="loginstate" class="flex flex-col lg:flex-row list-none">
              <li
                class="nav-item mx-18"
                v-for="(item, index) in navItems"
                :key="index"
                @click="isActive = false"
              >
                <router-link
                  :to="
                    item.name != 'Home' && !$store.state.authentication.loggedIn
                      ? '/auth/login'
                      : item.to
                  "
                  v-slot="{ href, navigate, isActive }"
                >
                  <a
                    :href="href"
                    @click="navigate"
                    class="px-3 py-2 flex items-center leading-snug hover:opacity-75"
                    :class="[
                      isActive
                        ? 'isActive'
                        : 'text-blueGray-700 hover:text-blueGray-500',
                    ]"
                  >
                    {{ item.name }}
                  </a>
                </router-link>
              </li>
            </ul>
            <div
              class="px-4 mt-30 block w-full lg:hidden"
              v-if="!$store.state.authentication.loggedIn"
            >
              <button class="btn-purple w-full" @click="goToSignIn()">
                Sign in
              </button>
            </div>
            <div class="px-4 mt-30 text-white flex lg:hidden" v-else>
              <button class="btn-purple w-full" @click="goToInventory()">
                Auction Item
              </button>
            </div>
          </div>
          <div
            class="px-4 hidden lg:block"
            v-if="!$store.state.authentication.loggedIn"
          >
            <button class="btn-purple" @click="goToSignIn()">Sign in</button>
          </div>
          <div
            class="w-full px-4 flex items-center text-white justify-end relative"
            v-else
          >
            <button class="p-10 text-20 text-gray-500">
              <i class="fas fa-bell"></i>
            </button>
            <button
              class="btn-purple w-auto mx-20 hidden lg:block"
              @click="goToInventory()"
            >
              Auction Item
            </button>
            <button
              class="border-1 border-solid border-gray-100 p-5 lg:pr-15 mr-10 lg:mr-0 text-14 lg:text-17 text-white leading-24 font-medium rounded-full flex items-center balance-effect"
              v-on:click="addDropdownClass()"
            >
              <span class="w-32-px h-32-px rounded-full overflow-hidden">
                <img
                  v-bind:src="profile && profile.imageUrl"
                  class="img-responsive"
                />
              </span>
              <p class="hidden lg:block ml-6">
                {{ profile && profile.balance ? parseFloat(profile.balance).toFixed(4) : '0.0000' }}
                <span class="text-green">UNITS</span>
              </p>
            </button>
            <div
              class="user-dropdown absolute right-0 top-100 min-w-219 z-50 2xl:right-20-px hidden"
              :class="isDropdown ? 'show-dropdown' : ''"
            >
              <div
                class="w-full bg-black-900 border-1 border-solid border-darkgray rounded-25 px-20 pb-28"
              >
                <ul>
                  <li class="mt-30">
                    <a href="#" class="flex items-center">
                      <span class="mr-12">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          class="transition-all"
                          version="1.2"
                          baseProfile="tiny-ps"
                          viewBox="0 0 15 13"
                          width="30"
                          height="30"
                          style="stroke: #707070; stroke-width: 0.7"
                        >
                          <path
                            id="Ellipse 37"
                            class="shp0"
                            d="M4.25 12.5C2.18 12.5 0.5 10.82 0.5 8.75C0.5 6.68 2.18 5 4.25 5C6.32 5 8 6.68 8 8.75C8 10.82 6.32 12.5 4.25 12.5Z"
                          />
                          <path
                            id="Ellipse 38"
                            class="shp0"
                            d="M4.25 10.5C2.18 10.5 0.5 8.82 0.5 6.75C0.5 4.68 2.18 3 4.25 3C6.32 3 8 4.68 8 6.75C8 8.82 6.32 10.5 4.25 10.5Z"
                          />
                          <path
                            id="Ellipse 39"
                            class="shp0"
                            d="M4.25 8C2.18 8 0.5 6.32 0.5 4.25C0.5 2.18 2.18 0.5 4.25 0.5C6.32 0.5 8 2.18 8 4.25C8 6.32 6.32 8 4.25 8Z"
                          />
                          <path
                            id="Ellipse 40"
                            class="shp0"
                            d="M12 12.5C10.34 12.5 9 11.16 9 9.5C9 7.84 10.34 6.5 12 6.5C13.66 6.5 15 7.84 15 9.5C15 11.16 13.66 12.5 12 12.5Z"
                          />
                          <path
                            id="Ellipse 41"
                            class="shp0"
                            d="M12 10.5C10.34 10.5 9 9.16 9 7.5C9 5.84 10.34 4.5 12 4.5C13.66 4.5 15 5.84 15 7.5C15 9.16 13.66 10.5 12 10.5Z"
                          />
                        </svg>
                      </span>
                      <router-link
                        class="text-white-200 text-17 transition-all font-normal"
                        :to="'/myaccount/1'"
                        @click.native="closeDropdown"
                        >My Auctions</router-link
                      >
                    </a>
                  </li>
                  <li class="mt-30">
                    <a href="#" class="flex items-center">
                      <span class="mr-12">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          class="transition-all"
                          version="1.2"
                          baseProfile="tiny-ps"
                          viewBox="0 0 14 17"
                          width="30"
                          height="30"
                          style="stroke: #707070; stroke-width: 0.7"
                        >
                          <path
                            id="Path 164"
                            class="shp0"
                            d="M9.4 0.68L13.63 6.72C14.11 7.41 13.87 8.4 13.1 8.94L7.51 12.86C6.74 13.4 5.72 13.28 5.24 12.6L1.01 6.55C0.53 5.87 0.77 4.88 1.54 4.34L7.14 0.42C7.38 0.25 7.64 0.14 8.17 0.08C8.49 0.01 9.07 0.21 9.4 0.68Z"
                          />
                          <path
                            id="Path 165"
                            class="shp1"
                            d="M8.98 4.24L13.22 10.28C13.69 10.97 13.48 11.94 12.73 12.47L7.31 16.27C6.56 16.79 5.56 16.66 5.08 15.98L0.85 9.93C0.37 9.25 0.59 8.27 1.34 7.74L6.76 3.95C6.99 3.79 7.25 3.69 7.76 3.63C8.08 3.56 8.65 3.77 8.98 4.24Z"
                          />
                        </svg>
                      </span>
                      <router-link
                        class="text-white-200 text-17 transition-all font-normal"
                        :to="'/myaccount/4'"
                        @click.native="closeDropdown"
                        >Developers</router-link
                      >
                    </a>
                  </li>
                  <li class="mt-30">
                    <a href="#" class="flex items-center">
                      <span class="mr-12">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          class="transition-all"
                          version="1.2"
                          baseProfile="tiny-ps"
                          viewBox="0 0 15 10"
                          width="30"
                          height="30"
                          style="stroke: #707070; stroke-width: 0.7"
                        >
                          <path
                            id="Rectangle 394"
                            class="shp0"
                            d="M0.5 2C0.5 0.9 1.4 0 2.5 0L12.5 0C13.6 0 14.5 0.9 14.5 2L14.5 8C14.5 9.1 13.6 10 12.5 10L2.5 10C1.4 10 0.5 9.1 0.5 8L0.5 2Z"
                          />
                          <path
                            id="Line 54"
                            class="shp1"
                            d="M0.75 3.25L13.75 3.25"
                          />
                        </svg>
                      </span>
                      <router-link
                        class="text-white-200 text-17 transition-all font-normal"
                        :to="'/myaccount/3'"
                        @click.native="closeDropdown"
                        >Ads and revenue</router-link
                      >
                    </a>
                  </li>
                  <li class="mt-30">
                    <a href="#" class="flex items-center">
                      <span class="mr-12">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          class="transition-all"
                          version="1.2"
                          baseProfile="tiny-ps"
                          viewBox="0 0 13 12"
                          width="30"
                          height="30"
                          style="stroke: #707070; stroke-width: 0.7"
                        >
                          <path id="Line 53" class="shp0" d="M1.5 0.5L1.5 11" />
                          <path id="Line 58" class="shp0" d="M6.5 0.5L6.5 11" />
                          <path id="Line 59" class="shp0" d="M11 0.5L11 11" />
                          <path
                            id="Path 167"
                            class="shp1"
                            d="M6.5 6.5C7.39 6.5 8.12 7.22 8.12 8.1C8.12 8.99 7.39 9.71 6.5 9.71C5.61 9.71 4.88 8.99 4.88 8.1C4.88 7.22 5.61 6.5 6.5 6.5Z"
                          />
                          <path
                            id="Path 169"
                            class="shp1"
                            d="M1.5 2.54C2.39 2.54 3.12 3.26 3.12 4.15C3.12 5.03 2.39 5.75 1.5 5.75C0.61 5.75 -0.12 5.03 -0.12 4.15C-0.12 3.26 0.61 2.54 1.5 2.54Z"
                          />
                          <path
                            id="Path 168"
                            class="shp1"
                            d="M11 2C11.89 2 12.62 2.72 12.62 3.6C12.62 4.49 11.89 5.21 11 5.21C10.11 5.21 9.38 4.49 9.38 3.6C9.38 2.72 10.11 2 11 2Z"
                          />
                        </svg>
                      </span>
                      <router-link
                        class="text-white-200 text-17 transition-all font-normal"
                        :to="'/myaccount/5'"
                        @click.native="closeDropdown"
                        >Settings</router-link
                      >
                    </a>
                  </li>
                  <li class="mt-30">
                    <a href="#" class="flex items-center">
                      <span class="mr-12">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          class="transition-all"
                          version="1.2"
                          baseProfile="tiny-ps"
                          viewBox="0 0 14 13"
                          width="30"
                          height="30"
                          style="stroke: #707070; stroke-width: 0.7"
                        >
                          <path
                            id="Rectangle 396"
                            class="shp0"
                            d="M8.95 0.82C10.06 0.82 10.95 1.72 10.95 2.82L10.95 10.3C10.95 11.4 10.06 12.3 8.95 12.3L5.45 12.3C4.35 12.3 3.45 11.4 3.45 10.3L3.45 2.82C3.45 1.72 4.35 0.82 5.45 0.82L8.95 0.82Z"
                          />
                          <path
                            id="Rectangle 395"
                            class="shp0"
                            d="M0 5C0 3.9 0.9 3 2 3L12 3C13.1 3 14 3.9 14 5L14 11C14 12.1 13.1 13 12 13L2 13C0.9 13 0 12.1 0 11L0 5Z"
                          />
                        </svg>
                      </span>
                      <router-link
                        class="text-white-200 text-17 transition-all font-normal"
                        :to="'/myaccount/2'"
                        @click.native="closeDropdown"
                        >Inventory</router-link
                      >
                    </a>
                  </li>
                  <li class="mt-30">
                    <a href="#" class="flex items-center">
                      <span class="mr-12">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          class="transition-all"
                          version="1.2"
                          baseProfile="tiny-ps"
                          viewBox="0 0 13 6"
                          width="30"
                          height="30"
                          style="stroke: #707070; stroke-width: 0.7"
                        >
                          <path
                            id="Line 55"
                            class="shp0"
                            d="M1.45 2.88L11.88 2.88"
                          />
                          <path
                            id="Line 56"
                            class="shp1"
                            d="M10.13 1.13L12.13 2.63"
                          />
                          <path
                            id="Line 57"
                            class="shp1"
                            d="M9.88 4.88L12.13 2.88"
                          />
                        </svg>
                      </span>
                      <h6
                        class="text-white-200 text-17 transition-all font-normal"
                        @click="logoutUser"
                      >
                        Log out
                      </h6>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <button
              class="cursor-pointer text-xl leading-none px-3 py-1 border border-solid border-transparent rounded bg-transparent block lg:hidden outline-none focus:outline-none"
              v-on:click="addBodyClass()"
              v-bind:class="{ active: isActive }"
              type="button"
            >
              <span class="block relative w-6 h-px rounded-sm bg-white"></span>
              <span
                class="block relative w-6 h-px rounded-sm bg-white mt-1"
              ></span>
              <span
                class="block relative w-6 h-px rounded-sm bg-white mt-1"
              ></span>
            </button>
          </div>
        </div>
      </nav>
    </div>
  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
export default {
  props: {
    menuhideshow: Boolean,
  },
  data() {
    return {
      isActive: false,
      isDropdown: false,
      loginstate: this.menuhideshow,
      navItems: [
        {
          to: "/games",
          icon: "fa-play",
          name: "Games",
        },
        {
          to: "/browseauctions",
          icon: "fa-search",
          name: "Market",
        },
        {
          to: "/wallets",
          icon: "fa-search",
          name: "Wallets",
        },
        {
          to: "/titlemarket",
          icon: "fa-search",
          name: "Store",
        },
        {
          to: "/myAccount/3",
          icon: "fa-search",
          name: "Ads",
        },
      ],
    };
  },
  computed: {
    ...mapGetters("profile", ["profile"]),
  },
  mounted() {
    if (!this.$store.state.authentication.loggedIn) {
      this.navItems.push({
        to: "/home",
        icon: "fa-home",
        name: "Home",
      });
    }
    this.getProfile();
  },
  // watch: {
  //   "$route.name": {
  //     handler: function () {
  //       if (!this.$store.state.authentication.loggedIn) {
  //         this.navItems.push({
  //           to: "/home",
  //           icon: "fa-home",
  //           name: "Home",
  //         });
  //       }
  //     },
  //   },
  // },
  created() {
    window.addEventListener("click", this.close);
  },
  beforeDestroy() {
    window.removeEventListener("click", this.close);
  },
  methods: {
    ...mapActions("authentication", ["logoutUser"]),
    ...mapActions("profile", ["getProfile"]),
    addBodyClass: function () {
      this.isActive = !this.isActive;
      this.isDropdown = false;
    },
    addDropdownClass: function () {
      this.isDropdown = !this.isDropdown;
      this.isActive = false;
    },
    goToSignIn() {
      this.$router.push("/auth/login");
    },
    close(e) {
      if (!this.$el.contains(e.target)) {
        this.isDropdown = false;
        this.isActive = false;
      }
    },
    closeDropdown() {
      this.isDropdown = false;
    },
    goToInventory() {
      this.$router.push("/myaccount/2");
    },
  },
};
</script>
<style scoped>
.header-block {
  border-bottom: 1px solid #52525362;
}

#header-nav a {
  font-size: 18px;
  color: #9396ab;
}

#header-nav a.isActive {
  border-bottom: 4px solid #685ff3;
}
.user-dropdown a:hover h6 {
  color: #fff;
}
.user-dropdown a:hover svg {
  stroke: #fff !important;
}
.user-dropdown.show-dropdown {
  display: block;
}

@media (max-width: 1024px) {
  #header-nav {
    display: none;
  }
  #header-nav.hideshowdiv {
    display: block;
  }
}
.sparkle-effetc {
  position: relative;
}
/* .sparkle-effetc:after {
  background-image: url(/img/sparkle.e591eb82.gif);
  height: 100%;
  width: 100%;
  content: "";
  position: absolute;
  right: -45px;
  left: inherit;
  top: 0;
  background-size: 80px;
  background-repeat: no-repeat;
} */
</style>
